<template>
  <section
    class="background w-full h-screen fixed top-0 left-0 z-50 flex items-center justify-center"
    v-if="modalIsOpen"
    @click="closeModal"
  >
    <div class="modal__content relative modal__animation rounded">
      <h2 class="text-center text-xl lg:text-2xl color__text mb-0 px-2">
        {{ modalText }}<br />
      </h2>
      <span
        class="w-full text-center font-bold text-xl lg:text-3xl text-red-600 mt-0"
        >{{ modalFeaturedText }}</span
      >
      <button
        class="bg-white rounded px-2 absolute top-3 right-3 border transition duration-300 hover:bg-gray-200"
        @click="closeModal"
      >
        X
      </button>
      <footer
        v-if="buttonOptions"
        class="w-full p-3 flex items-center justify-center gap-5 mt-3"
      >
        <button
          class="bg-gray-200 w-32 px-7 py-2 rounded-full text-gray-700 text-base transition duration-300 hover:bg-gray-300"
          @click="confirmarDelete(DeleteId)"
        >
          Sim
        </button>
        <button
          class="bg-gray-200 w-32 px-7 py-2 rounded-full text-gray-700 text-base transition duration-300 hover:bg-gray-300"
          @click="closeModal"
        >
          Não
        </button>
      </footer>
    </div>
  </section>
</template>

<script>
export default {
  name: "Modal",
  props: {
    modalIsOpen: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    confirmarDelete: {
      type: Function,
      required: false,
    },
    DeleteId: {
      type: String,
      require:false,
    },
    modalText: {
      type: String,
      required: true,
    },
    modalFeaturedText: {
      type: String,
      required: false,
    },
    buttonOptions: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.background {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal__content {
  background-color: white;
  width: 90%;
  max-width: 800px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.color__text {
  color: #5b6873;
}

.modal__animation {
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  from {
    transform: translate3d(50px, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}
</style>
