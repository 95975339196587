import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const state = {
  groupUsers: [],
  groupUsersPage: [],
}

const getters = {
  groupUsers: state => state.groupUsers,
}

const mutations = {
  SET_GROUP_USERS(state, value) {
    state.groupUsers = value
  },
  SET_PAGE(state, value) {
    state.groupUsersPage = value;
  }
}

const actions = {
  async totalGroupUsers({ commit }) {
    let auth = localStorage.getItem('userKey');
    let authStr = JSON.parse(auth);
    let resGroupUsers = [];
    
    const reqGet = await axios.get(`${process.env.VUE_APP_BASE_URL}/grupoUsuario/obter-grid/1`, {
      headers: { 'Authorization': `Bearer ${authStr}`, }
    });

    const resApi = await reqGet.data.totalDePaginas;
    const totalPages = resApi;

    for (let i = 1; i <= totalPages; i++) {
      let reqTotalUsers = await axios.get(`${process.env.VUE_APP_BASE_URL}/grupoUsuario/obter-grid/${i}`, {
        headers: { 'Authorization': `Bearer ${authStr}`, }
    });

     for (let i in reqTotalUsers.data.itens) {
       const resTotalUsers = await reqTotalUsers.data.itens[i];

       resGroupUsers.push(resTotalUsers);
       commit('SET_GROUP_USERS', resGroupUsers);
     }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}