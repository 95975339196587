<template>
  <div>
    <section v-show="showRegister">
      <RegisterFormFarm />
    </section>
    <section v-show="showEdit">
      <EditFormFarm :dataFormFarm="$route.params.formFarma" />
    </section>
  </div>
</template>

<script>
  import RegisterFormFarm from '@/components/formFarma/RegisterFormFarm.vue';
  import EditFormFarm from '@/components/formFarma/EditFormFarm.vue';

  export default {
    components: {
      RegisterFormFarm,
      EditFormFarm,
    },
  
    data() {
      const { activeRegister, activeEdit } = this.$route.params;

      return {
        showRegister: activeRegister,
        showEdit: activeEdit,
      };
    },
  }
</script>