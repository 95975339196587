import axios from "axios";
import Constants from "@/helpers/constants/localStorage";
import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const state = {
  user: {},
  active: {},
  erroLogin: "",
};

const getters = {
  user: (state) => state.user,
  // active: state => state.active,
};

const mutations = {
  SET_USER(state, value) {
    state.user = value;
  },
  SET_ERRORLOGIN(state, value) {
    state.erroLogin = value;
  },
  SET_ACTIVE(state, value) {
    state.active = value;
  },
};

const actions = {
  async signIn({ commit }, user) {
    const header = {
      "Content-Type": "application/json",
    };
    //athentication
    await axios
      .post(`${process.env.VUE_APP_BASE_URL}/login/autenticar`, user, header)
      .then((res) => {
        commit("SET_ERRORLOGIN", "");

        localStorage.setItem(
          Constants.USER_KEY,
          JSON.stringify(res.data.token)
        );
        const user_Id = res.data.contaSelecionada.id;

        //User is activated
        axios
          .get(`${process.env.VUE_APP_BASE_URL}/conta/obter/${user_Id}`)
          .then((response) => {
            const active = response.data.ativo;
            commit("SET_ACTIVE", active);
            console.log("activo", active);
          });
      })
      //Erro on login
      .catch(function (error) {
        if (error.response) {
          const erroLogin = error.response.data;
          commit("SET_ERRORLOGIN", erroLogin);
        }
      });
  },
  //Logout
  logout() {
    localStorage.removeItem("userKey");
    // this.$store.commit('SET_USER', {})
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
