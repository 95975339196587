var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-col sm:flex-row justify-between items-start sm:items-center header__register",class:{
    'pb-1': _vm.showButtonView && _vm.subTitleText,
    'pb-3':
      (_vm.showButtonView && !_vm.subTitleText) || (!_vm.showButtonView && _vm.subTitleText),
    'pb-4': !_vm.showButtonView && !_vm.subTitleText,
  }},[(_vm.subTitleText)?_c('div',{staticClass:"flex flex-col items-start"},[_c('h2',{staticClass:"m-0 text-2xl sm:text-3xl"},[_vm._t("default")],2),_c('p',{staticClass:"w-full text-sm sm:text-left text-gray-600 font-normal mt-1"},[_vm._v(" "+_vm._s(_vm.subTitleText)+" ")])]):_c('h2',{staticClass:"m-0 text-2xl sm:text-3xl"},[_vm._t("default")],2),(_vm.buttonText)?_c('div',{staticClass:"w-full sm:w-auto",class:{
      flex: _vm.subTitleText,
      'justify-end': _vm.subTitleText,
    }},[(_vm.showButtonView)?_c('b-button',{staticClass:"btnNovo  text-sm sm:text-lg",attrs:{"variant":"danger"},on:{"click":function($event){_vm.click || _vm.RedirectUrl}}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e()],1):_c('div',[(_vm.showButtonView)?_c('b-button',{staticClass:"btnIncluir  text-sm sm:text-lg",attrs:{"variant":"danger"},on:{"click":function($event){_vm.click != null ? _vm.click() : _vm.RedirectUrl()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.toInclude"))+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }