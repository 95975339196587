import Vue from "vue";

// axios
import axios from "axios";

const timeInMinutes = 1000 * 60; // 1 min

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: timeInMinutes,
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
