import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const state = {
  groupPermission: [],
}

const mutations = {
  SET_GROUP_PERMISSION(state, value) {
    state.groupPermission = value
  }
}

const actions = {
  async totalGroupUsers({ commit }) {
    let auth = localStorage.getItem('userKey');
    let authStr = JSON.parse(auth);
    let resGroupPermission = [];

    const reqGet = await axios.get(`${process.env.VUE_APP_BASE_URL}/grupoPermissao/obter/1`, {
      headers: { 'Authorization': `Bearer ${authStr}`, }
    });

    const resApi = await reqGet.data.groupPermission;
    const group = resApi;

    for (let i = 1; i <= group; i++) {
      let reqTotalGroup = await axios.get(`${process.env.VUE_APP_BASE_URL}/grupoPermissao/obter/${i}`, {
        headers: { 'Authorization': `Bearer ${authStr}`, }
      });

      for (let i in reqTotalGroup.data.itens) {
        const resTotalGroup = await reqTotalGroup.data.itens[i];

        resGroupPermission.push(resTotalGroup);
        commit('SET_GROUP_PERMISSION', resGroupPermission);
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}