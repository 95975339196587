<template>
  <div class="content-editContact">
     <form class="form-editContact">
        <h4>{{ $t("sectionRegisters.editDatas") }}</h4>
        <label>
          <h6>{{ $t("sectionTypeContact.description") }}</h6>
          <input v-model="description" type="text">
        </label>
        <button @click="save">{{ $t("sectionRegisters.save") }}</button>
     </form>
     <router-link style="margin-right: 0px" to="/typeContact/areaTypesContacts">
        <button class="btn-returnContact">{{ $t("sectionRegisters.back") }}</button>
    </router-link>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'EditContacts',
    props: {
      dataContacts: Object,
    },
  
    data() {
      return {
        description: this.dataContacts.descricao,
        editContact: '',
      }
    },
  
    async mounted() {
      this.$store.dispatch('stateTypesContacts/totalTypesContacts');
      
      const { descricao } = this.dataContacts;
      const { typesContacts } = this.$store.state.stateTypesContacts;
      const findContact = typesContacts.find(user => user.descricao == descricao);

      this.editContact = findContact;
    },
  
    methods: {
      save(e) {
        e.preventDefault();

        if (this.description != this.dataContacts.descricao) {
          let auth = localStorage.getItem('userKey');
          let authStr = JSON.parse(auth);

          const { integracaoId, id } = this.editContact;

          const bodyRequest = {
            id: id,
            descricao: this.description,
            integracaoId: integracaoId,
            excluido: false,
          }

          axios.post(`${process.env.VUE_APP_BASE_URL}/tipoContato/editar`, bodyRequest, {
            headers: { 'Authorization': `Bearer ${authStr}`, }
          })
          .then(() => {
            this.description = '';
            this.$store.dispatch('stateTypesContacts/totalTypesContacts');
          })
          .catch(() => console.log('Houve um erro.'));
        }
      }
    }
  }
</script>

<style scoped>
  .content-editContact {
    display: flex;
    flex-direction: column;
  }

  .form-editContact {
    display: flex;
    flex-direction: column;
  }

  .form-editContact label {
    margin-top: 10px;
  }

  .form-editContact label h6 {
    margin-right: 150px;
    font-size: 1.2em;
  }

  .form-editContact label input {
    padding: 5px;
    margin-right: 35px;
  }

  .form-editContact button {
    padding: 5px;
    width: 100px;
    margin-right: 30px;
    text-align: center;
    align-self: center;
    color: black;
    border-radius: 5px;
    background-color: green;
  }

  .btn-returnContact {
    padding: 5px;
    width: 100px;
    margin-top: 10px;
    margin-right: 30px;
    text-align: center;
    align-self: center;
    color: black;
    border: 1px gray solid;
    border-radius: 5px;
  }
</style>