import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const state = {
  registerSiproquim: [],
  codSip : '',
  Descri:''
}

const mutations = {
  SET_TYPES_SIPROQUIM(state, value) {
    state.registerSiproquim = value
  }
}

const actions = {
  async totalSiproquim({ commit }) {
    let auth = localStorage.getItem('userKey');
    let authStr = JSON.parse(auth);
    let siproquins = [];
    
    const reqGet = await axios.get(`${process.env.VUE_APP_BASE_URL}/siproquim/obter-grid/1`, {
      headers: { 'Authorization': `Bearer ${authStr}`, }
    });

    const resApi = await reqGet.data.totalDePaginas;
    const totalPages = resApi;

    for (let i = 1; i <= totalPages; i++) {
      let reqSiproquim = await axios.get(`${process.env.VUE_APP_BASE_URL}/siproquim/obter-grid/${i}`, {
        headers: { 'Authorization': `Bearer ${authStr}`, }
    });

     for (let i in reqSiproquim.data.itens) {
       const resTotalUsers = await reqSiproquim.data.itens[i];

       siproquins.push(resTotalUsers);
       commit('SET_TYPES_SIPROQUIM', siproquins);
     }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}