import axios from 'axios'
import Vuex from 'vuex';
import Vue from 'vue'

Vue.use(Vuex);

const state = {
    methods: []
}

const mutations = {
    SET_METHODS(state, value){
        state.methods = value
    } 
}

const actions = {
    async methodsSearch({ commit }) {

     let methodsAllItens = []   
        
     let pageMethods =  await axios.get(`${process.env.VUE_APP_BASE_URL}/metodo/obter-grid/1` )
     
     let allPages = await pageMethods.data.totalDePaginas
     let  PagesOK = allPages 
     
     for ( let i = 1; i <= PagesOK; i++ ){
        let pageMethodsReq =  await axios.get(`${process.env.VUE_APP_BASE_URL}/metodo/obter-grid/${i}`, )    

        for ( let i in pageMethodsReq.data.itens ){
            const methodsTotal = await pageMethodsReq.data.itens[i];

            methodsAllItens.push(methodsTotal);

            commit('SET_METHODS', methodsAllItens);
        }
      }
    }
}

export default{
    namespaced: true, state, mutations, actions
}
