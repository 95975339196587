import Vue from "vue";
import Vuex from "vuex";
import axios from "@axios";

Vue.use(Vuex);

const state = {};

const mutations = {};

const actions = {
  async obterGrid(state, page) {
    await axios
      .get(`${process.env.VUE_APP_BASE_URL}/fornecedor/obter-grid/${page}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
        }
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
