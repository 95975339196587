import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
    ensaios: [{nome: 'Teste', description: 'Eu sou um Teste'}],
    farmacopeias: [{nome: 'Teste', description: 'Eu sou um Teste'}],
    produtos: [{descrição: 'Teste',
                comissão: null,
                desconto: null,
                max_desconto: null,
                markup: null,
                grupo: null,
                lotes: null,
                tipo: null,
                pesagem: null,
                ensaio: {
                    nome: null,
                    farmacopeia: null,
                }
            }]
                
};

const mutations = {
    changeEnsaio(state, payload) {
        state.ensaios.push(payload);
    },
    editEnsaio(state, payload) {
        state.ensaios[0] = payload;
    },
    changeFarmacopeia(state, payload) {
        state.farmacopeias.push(payload);
    },
    editFarmacopeia(state, payload) {
        state.farmacopeias[0] = payload;
    },
    changeProduto(state, payload) {
        state.produtos.push(payload);
    },
    editProduto(state, payload) {
        state.produtos[0] = payload;
    },
};

const actions = {};

const getters = {
    getEnsaio(state) {
        return state.ensaios;
    },
    getFarmacopeia(state) {
        return state.farmacopeias;
    },
    getProduto(state) {
        return state.produtos;
    }
};

export default {
  state,
  mutations,
  actions,
  getters,
};
