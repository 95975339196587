import Vue from "vue";
import Router from "vue-router";
import routes from "vue-auto-routing";
import { createRouterLayout } from "vue-router-layout";

import { isUserLoggedIn } from "@/auth/utils";

import addOrUpdate from "@/pages/groupUsers/addOrUpdate";
import addOrUpdateContacts from "@/pages/typeContact/addOrUpdateContacts";
import addOrUpdateSiproquim from "@/pages/siproquim/addOrUpdateSiproquim";
import addOrUpdatePbm from "@/pages/pbm/addOrUpdatePbm";
import addOrUpdateFormaFarma from "@/pages/formasFarma/addOrUpdateFormaFarma";
import addSiproquim from "@/pages/siproquim/addSiproquim.vue";
import viewSiproquim from "@/pages/siproquim/viewSiproquim.vue";
import editSiproquim from "@/pages/siproquim/editSiproquim.vue";


Vue.use(Router);

const RouterLayout = createRouterLayout((layout) => {
  return import("@/layouts/" + layout + ".vue");
});

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      component: RouterLayout,
      children: routes,
    },
    {
      path: "/groupUsers/addOrUpdate",
      name: "addOrUpdate",
      component: addOrUpdate,
    },
    {
      path: "/typeContact/addOrUpdateContacts",
      name: "addOrUpdateContacts",
      component: addOrUpdateContacts,
    },
    {
      path: "/siproquim/addOrUpdateSiproquim",
      name: "addOrUpdateSiproquim",
      component: addOrUpdateSiproquim,
    },
    {
      path: "/siproquim/addSiproquim",
      name: "addSiproquim",
      component: addSiproquim
    },
    {
      path: "/siproquim/viewSiproquim",
      name: "viewSiproquim",
      component: viewSiproquim
    },
    {
      path: "/siproquim/editSiproquim",
      name: "editSiproquim",
      component: editSiproquim
    },
    {
      path: "/pbm/addOrUpdatePbm",
      name: "addOrUpdatePbm",
      component: addOrUpdatePbm,
    },
    {
      path: "/formasFarma/addOrUpdateFormaFarma",
      name: "addOrUpdateFormaFarma",
      component: addOrUpdateFormaFarma,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!isLoggedIn && to.name != "index") return next({ name: "index" });

  return next();
});

export default router;
