<template>
    <div>
        <ViewSiproquim />
    </div>
  </template>
  
  <script>
    import ViewSiproquim from '@/components/siproquim/ViewSiproquim.vue';
  
    export default {
      components: {
        ViewSiproquim,

      },
      data() {
  
        return {
        };
      },
    }
  </script>

<style>

</style>