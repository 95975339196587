import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const state = {
    FormaFarma: [],
}

const mutations = {
    SET_FORMA_FARMA(state,value)
    {
        state.FormaFarma = value;
    }
}

const actions = {
    async totalFormaFarma({commit}){
    let auth = localStorage.getItem('userkey');
    let authStr = JSON.parse(auth);
    let FormaFarmaRes = [];

    const reqGet = await axios.get(`${process.env.VUE_APP_BASE_URL}/formaFarmaceutica/obter-grid/1`, {
        headers: { 'Authorization': `Bearer ${authStr}`, }
      });

      const resApi = await reqGet.data.totalDePaginas;
      const totalPages = resApi;

      for (let i = 1; i <= totalPages; i++) {
        let reqFormaFarma = await axios.get(`${process.env.VUE_APP_BASE_URL}/formaFarmaceutica/obter-grid/${i}`, {
          headers: { 'Authorization': `Bearer ${authStr}`, }
      });
  
       for (let i in reqFormaFarma.data.itens) {
         const resTotalForma = await reqFormaFarma.data.itens[i];
  
         FormaFarmaRes.push(resTotalForma);
         commit('SET_FORMA_FARMA', FormaFarmaRes);
       }
      }


    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
