import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const state = {
  typesContacts: [],
}

const mutations = {
  SET_TYPES_CONTACTS(state, value) {
    state.typesContacts = value
  }
}

const actions = {
  async totalTypesContacts({ commit }) {
    let auth = localStorage.getItem('userKey');
    let authStr = JSON.parse(auth);
    let typesContactsRes = [];
    
    const reqGet = await axios.get(`${process.env.VUE_APP_BASE_URL}/tipoContato/obter-grid/1`, {
      headers: { 'Authorization': `Bearer ${authStr}`, }
    });

    const resApi = await reqGet.data.totalDePaginas;
    const totalPages = resApi;

    for (let i = 1; i <= totalPages; i++) {
      let reqContacts = await axios.get(`${process.env.VUE_APP_BASE_URL}/tipoContato/obter-grid/${i}`, {
        headers: { 'Authorization': `Bearer ${authStr}`, }
    });

     for (let i in reqContacts.data.itens) {
       const resTotalUsers = await reqContacts.data.itens[i];

       typesContactsRes.push(resTotalUsers);
       commit('SET_TYPES_CONTACTS', typesContactsRes);
     }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}