<template>
  <div class="content-editPbm">
    <form class="form-editPbm">
      <h4>{{ $t("sectionRegisters.editDatas") }}</h4>
      <label>
        <h6 style="margin-right: 130px">integracao ID</h6>
        <input v-model="integracaoId" type="text" />
      </label>
      <label>
        <h6 style="margin-right: 180px">Nome</h6>
        <input v-model="nome" type="text" />
      </label>
      <label>
        <h6 style="margin-right: 160px">Observação</h6>
        <input v-model="observacao" type="text" />
      </label>
      <button @click="save">{{ $t("sectionRegisters.save") }}</button>
    </form>
    <router-link style="margin-right: 0px" to="/pbm/areaPbm">
      <button class="btn-returnPbm">{{ $t("sectionRegisters.back") }}</button>
    </router-link>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EditPbm",
  props: {
    dataPbm: Object,
  },

  data() {
    return {
      integracaoId: this.dataPbm.integracaoId,
      nome: this.dataPbm.nome,
      observacao: this.dataPbm.observacao,
      editPbm: "",
    };
  },

  async mounted() {
    this.$store.dispatch("pbmState/totalPbm");

    const { nome } = this.dataPbm;
    const { registerPbm } = this.$store.state.pbmState;
    const findPbm = registerPbm.find((user) => user.nome == nome);

    this.editPbm = findPbm;
  },

  methods: {
    save(e) {
      e.preventDefault();

      // if (this.nome != this.dataPbm.nome) {
      let auth = localStorage.getItem("userKey");
      let authStr = JSON.parse(auth);

      const { id } = this.editPbm;

      const bodyRequest = {
        id: id,
        integracaoId: this.integracaoId,
        nome: this.nome,
        observacao: this.observacao,
      };

      axios
        .post(`${process.env.VUE_APP_BASE_URL}/pbm/editar`, bodyRequest, {
          headers: { Authorization: `Bearer ${authStr}` },
        })
        .then(() => {
          this.integracaoId = "";
          this.nome = "";
          this.observacao = "";
          this.$store.dispatch("pbmState/totalPbm");
        })
        .catch(() => console.log("Houve um erro."));
    },
    // }
  },
};
</script>

<style scoped>
.content-editPbm {
  display: flex;
  flex-direction: column;
}

.form-editPbm {
  display: flex;
  flex-direction: column;
}

.form-editPbm label {
  margin-top: 10px;
}

.form-editPbm label h6 {
  margin-right: 150px;
  font-size: 1.2em;
}

.form-editPbm label input {
  padding: 5px;
  margin-right: 35px;
}

.form-editPbm button {
  padding: 5px;
  width: 100px;
  margin-right: 30px;
  text-align: center;
  align-self: center;
  color: black;
  border-radius: 5px;
  background-color: green;
}

.btn-returnPbm {
  padding: 5px;
  width: 100px;
  margin-top: 10px;
  margin-right: 30px;
  text-align: center;
  align-self: center;
  color: black;
  border: 1px gray solid;
  border-radius: 5px;
}
</style>
