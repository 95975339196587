<template>
  <div>
    <section v-show="showRegister">
      <RegisterPbm />
    </section>
    <section v-show="showEdit">
      <EditPbm :dataPbm="$route.params.pbm" />
    </section>
  </div>
</template>

<script>
  import RegisterPbm from '@/components/registerPbm/RegisterPbm.vue';
  import EditPbm from '@/components/registerPbm/EditPbm.vue';

  export default {
    components: {
      RegisterPbm,
      EditPbm,
    },
    data() {
      const { activeRegister, activeEdit } = this.$route.params;

      return {
        showRegister: activeRegister,
        showEdit: activeEdit,
      };
    },
  }
</script>