import { extend } from "vee-validate";
import {
  required as rule_required,
  email as rule_email,
  max_value as rule_max_value,
  min as rule_min,
  max as rule_max,
  double as rule_double,
  min_value as rule_min_value,
  numeric as rule_numeric,
  length as rule_length,
  integer as rule_integer,
} from "vee-validate/dist/rules";

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend("required", {
  ...rule_required,
  message: "'{_field_}' é obrigatório",
});
export const email = extend("email", {
  ...rule_email,
  message: "'{_field_} não é um email válido",
});
export const min = extend("min", {
  ...rule_min,
  message: "'{_field_}' inválido",
});
export const max = extend("max", {
  ...rule_max,
  message: "'{_field_}' inválido",
});
export const double = extend("double", {
  ...rule_double,
  message: "'{_field_}' inválido",
});
export const decimal = {
  message: "'{_field_}' inválido",
  validate: (value,params, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regex = new RegExp(`^[-+]?\\d{0,${params[0] ||'*'}}(\\${separator}\\d{0,${params[1] || '*'}})?([eE]{9}[-]?\\d+)?$`);
    return {
      valid: regex.test(value),
    };
  },
};
export const max_value = extend("max_value", {
  ...rule_max_value,
  message: "'{_field_}' máximo de caracteres {length}",
});
export const min_value = extend("min_value", {
  ...rule_min_value,
  message: "'{_field_}' mínimo de caracteres {length}",
});
export const numeric = extend("numeric", {
  ...rule_numeric,
  message: "'{_field_}' não é um número válido",
});

export const length = extend("length", {
  ...rule_length,
  message: "'{_field_}' tamanho de {length} caracteres",
});
export const integer = extend("integer", {
  ...rule_integer,
  message: "'{_field_}' inválido",
});

export const positive = {
  message: "'{_field_}' inválido",
  validate (value) {
    return parseInt(value) >= 0
  }
};
export const maiorQ = {
  message: "'{_field_}' inválido",
  validate (value,params) {
    return parseInt(value) < params
  }
};
export const cpfCnpj = {
  message: "'{_field_}' inválido",
  validate (value) {
    if (value?.replace(/[^0-9,]*/g, "").length == 11) {
      if (value?.replace(/[^0-9,]*/g, "") == "00000000000" ||
          value?.replace(/[^0-9,]*/g, "") == "11111111111" ||
          value?.replace(/[^0-9,]*/g, "") == "22222222222" ||
          value?.replace(/[^0-9,]*/g, "") == "33333333333" ||
          value?.replace(/[^0-9,]*/g, "") == "44444444444" ||
          value?.replace(/[^0-9,]*/g, "") == "55555555555" ||
          value?.replace(/[^0-9,]*/g, "") == "66666666666" ||
          value?.replace(/[^0-9,]*/g, "") == "77777777777" ||
          value?.replace(/[^0-9,]*/g, "") == "88888888888" ||
          value?.replace(/[^0-9,]*/g, "") == "9999999999")
        return false;
      
      var Resto;
      var Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(value?.replace(/[^0-9,]*/g, "").substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(value?.replace(/[^0-9,]*/g, "").substring(10, 11)))
        return false;
      
    }else if (value?.replace(/[^0-9,]*/g, "").length > 11){
      if (value?.replace(/[^0-9,]*/g, "") == "00000000000000" ||
          value?.replace(/[^0-9,]*/g, "") == "11111111111111" ||
          value?.replace(/[^0-9,]*/g, "") == "22222222222222" ||
          value?.replace(/[^0-9,]*/g, "") == "33333333333333" ||
          value?.replace(/[^0-9,]*/g, "") == "44444444444444" ||
          value?.replace(/[^0-9,]*/g, "") == "55555555555555" ||
          value?.replace(/[^0-9,]*/g, "") == "66666666666666" ||
          value?.replace(/[^0-9,]*/g, "") == "77777777777777" ||
          value?.replace(/[^0-9,]*/g, "") == "88888888888888" ||
          value?.replace(/[^0-9,]*/g, "") == "99999999999999") 
        return false;
      
    var tamanho = value?.replace(/[^0-9,]*/g, "").length - 2
    var numeros = value?.replace(/[^0-9,]*/g, "").substring(0, tamanho);
      var digitos = value?.replace(/[^0-9,]*/g, "").substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros?.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos?.charAt(0))
        return false;
      
    tamanho = tamanho + 1;
    numeros = value?.replace(/[^0-9,]*/g, "").substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros?.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos?.charAt(1))
        return false;
    }
    return true;
  }
}
export const inscEstadual = {
  message: "'{_field_}' inválido",
  validate (value, params) {
    value = value.replace(/[^a-z0-9]/gi, '')
    if ( value.toUpperCase() == "ISENTO" || value.toUpperCase() == "ISENTA") return true;
    if (params == null || params == '') return false;
    var soma = 0;
    var nro = new Array(9);
    var dig = -1;
    var NumMod = 0;
    switch(params[0].toUpperCase())
    {
      case "EX": return true;
      case "AC":
            if (value.length != 13)
              return false;
            var b = 4;
            soma = 0;
            for (var i=0; i<=10; i++)
            {
                soma += parseInt(value.charAt(i)) * b;
                --b;
                if (b == 1)
            b = 9;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
          dig = 0;
            if (dig != parseInt(value.charAt(11)))
          return false;
            b = 5;
            soma = 0;
            for (let i =0; i <= 11; i++)
            {
                soma += parseInt(value.charAt(i)) * b;
                --b;
                if (b == 1)
            b = 9;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
          dig = 0;
            return (dig == parseInt(value.charAt(12)));
      case "AL":
            if (value.length != 9)
            return false;
            for (let i = 0; i <= 7; i++)
            {
                let b = 9;
                soma += parseInt(value.charAt(i)) * b;
                --b;
            }
            soma *= 10;
            dig = soma - (Math.floor(soma / 11) * 11);
            if (dig == 10) 
          dig = 0;
            return (dig == parseInt(value.charAt(8)));
      case "AM":

            if (value.length != 9)
              return false;
              soma = 0;
              for (let i=0; i<=7; i++)
              {
                let b = 9
                soma += parseInt(value.charAt(i)) * b;
                b--;
            }
            if (soma < 11)
        {
          dig = 11 - soma;
            }
            else
            {
              i = soma % 11;
              if (i <= 1) 
          {
            dig = 0;
          }
          else
          {
            dig = 11 - i;
          }
            }
            return (dig == parseInt(value.charAt(8)));
      case "AP":

            if (value.length != 9)
              return false;
        if (value.substring(0,2) != "03")
          return false;
          var p = 0;
          var d = 0;
          var numero = value.substring(1, 8);
            if ((numero >= 3000001) && (numero <= 3017000))
            {
              p =5;
              d = 0;
            }
            else if ((numero >= 3017001) && (numero <= 3019022))
            {
              p = 9;
              d = 1;
            }
            b = 9;
            soma = p;
            for (let i=0; i<=7; i++)
            {
                soma += parseInt(value.charAt(i)) * b;
                b--;
            }
            dig = 11 - (soma % 11);
            if (dig == 10)
            {
              dig = 0;
            }
            else if (dig == 11)
            {
              dig = d;
            }
            return (dig == parseInt(value.charAt(8)));
      case "BA":
      
            if (value.length == 8)
        {
            var die = value.substring(0, 8);
              nro = new Array(8);
              dig = -1;
              for (let i=0; i<=7; i++)
                  nro[i] = parseInt(die.charAt(i));
              NumMod = 0;
              if (String(nro[0]).match(/[0123458]/))
            {
                  NumMod = 10;
          }
              else
          {
                  NumMod = 11;
          }
          b = 7;
              soma = 0;
              for (i=0; i<=5; i++)
              {
                  soma += nro[i] * b;
                  b--;
              }
              i = soma % NumMod;
              if (NumMod == 10)
              {
                  if (i == 0) { dig = 0; } else { dig = NumMod - i; }
              }
              else
              {
                  if (i <= 1) { dig = 0; } else { dig = NumMod - i; }
              }
              var resultado = (dig == nro[7]);
              if (!resultado)
                return false;
              b = 8;
              soma = 0;
              for (i=0; i<=5; i++)
              {
                  soma += nro[i] * b;
                  b--;
              }
              soma += nro[7] * 2;
              i = soma % NumMod;
              if (NumMod == 10)
              {
                  if (i == 0) { dig = 0; } else { dig = NumMod - i; }
              }
              else
              {
                  if (i <= 1) { dig = 0; } else { dig = NumMod - i; }
              }
              return (dig == nro[6]);
        }

            if (value.length == 9)
        {
              die = value.substring(0, 9);
              nro = new Array(9);
              dig = -1;
              for (let i=0; i<=8; i++)
                  nro[i] = parseInt(die.charAt(i));
              NumMod = 0;
              if (String(nro[0]).match(/[0123458]/))
              {
                  NumMod = 10;
          }
              else
            {
                  NumMod = 11;
            }
            b = 8;
              soma = 0;
              for (i=0; i<=6; i++)
              {
                  soma += nro[i] * b;
                  b--;
              }
              i = soma % NumMod;
              if (NumMod == 10)
              {
                  if (i == 0) { dig = 0; } else { dig = NumMod - i; }
              }
              else
              {
                  if (i <= 1) { dig = 0; } else { dig = NumMod - i; }
              }
              resultado = (dig == nro[8]);
              if (!resultado)
                  return false;
              b = 9;
              soma = 0;
              for (i=0; i<=6; i++)
              {
                  soma += nro[i] * b;
                  b--;
              }  
              soma += nro[8] * 2;
              i = soma % NumMod;
              if (NumMod == 10)
              {
                  if (i == 0) { dig = 0; } else { dig = NumMod - i; }
              }
              else
              {
                  if (i <= 1) { dig = 0; } else { dig = NumMod - i; }
              }
              return (dig == nro[7]);
        }
        return false;
      case "CE":
      
            if (value.length != 9)
              return false;
            die = value;
            nro = Array(9);
            for (let i=0; i<=8; i++)
                nro[i] = parseInt(die[i]);
            b = 9;
            soma = 0;
            for (i=0; i<=7; i++)
            {
                soma += nro[i] * b;
                b--;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
              dig = 0;
            return (dig == nro[8]);
      case "DF":
      
            if (value.length != 13)
              return false;
            nro = new Array(13);
            for (let i=0; i<=12; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 4;
            soma = 0;
            for (i=0; i<=10; i++)
            {
                soma += nro[i] * b;
                b--;
                if (b == 1)
                  b = 9;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
              dig = 0;
            if (dig != nro[11])
              return false;  
            b = 5;
            soma = 0;
            for (i=0; i<=11; i++)
            {
                soma += nro[ i ] * b;
                b--;
                if (b == 1)
                  b = 9;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
              dig = 0;
            return (dig == nro[12]);
      case "ES":

            if (value.length != 9)
              return false;
            nro = new Array(9);
            for (let i=0; i<=8; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 9;
            soma = 0;
            for (i=0; i<=7; i++)
            {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i < 2) { dig = 0; } else { dig = 11 - i; }
            return (dig == nro[8]);
      case "GO":
      
            if (value.length != 9)
              return false;
            var s = value.substring(0, 2);
            if ((s == '10') || (s == '11') || (s == '15'))
            {
              nro = new Array(9);
              for (let i=0; i<=8; i++)
                  nro[i] = parseInt(value.charAt(i));
              //n = Math.floor(value / 10);
              var n = parseInt(value.substring(0, 7));
              if (n == 11094402)
              {
                  if ((nro[8] == 0) || (nro[8] == 1))
                    return true;
              }
              b = 9;
              soma = 0;
              for (i=0; i<=7; i++)
              {
                  soma += nro[i] * b;
                  b--;
              }
              i = soma % 11;
              if (i == 0)
                  dig = 0;
              else
              {
                  if (i == 1)
                  {
                      if ((n >= 10103105) && (n <= 10119997))
                        dig = 1;
                      else
                        dig = 0;
                  }
                  else
                      dig = 11 - i;
              }
              return (dig == nro[8]);
            }
            return false;
      case "MA":

            if (value.length != 9)
              return false;
            if ( value.substring(0, 2) != "12" )
          return false;
            nro = new Array(9);
            for (let i=0; i<=8; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 9;
            soma = 0;
            for (i=0; i<=7; i++)
            {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i <= 1) { dig = 0; } else { dig = 11 - i; }
            return (dig == nro[8]);
      case "MG":
      
            if (value.length != 13)
              return false;
            var dig1 = value.substring(11, 12);
            var dig2 = value.substring(12, 13);
            var inscC = value.substring(0, 3) + '0' + value.substring(3, 11);
            var insc = inscC.split('');
            var npos = 11;
            i = 1;
          var  ptotal = 0;
            var psoma = 0;
            while (npos >= 0)
            {
                  i++;
                  psoma = parseInt(insc[npos]) * i;
                  if (psoma >= 10)
                    psoma -= 9;
                  ptotal += psoma;
                  if (i == 2)
                    i = 0;
                  npos--;
            }
          var  nresto = ptotal % 10;
            if (nresto == 0)
              nresto = 10;
            nresto = 10 - nresto;
            if (nresto != parseInt(dig1))
              return false;
            npos = 11;
            i = 1;
            ptotal = 0;
            var is=value.split('');
            while (npos >= 0)
            {
                  i++;
                  if (i == 12)
                    i = 2;
                  ptotal += parseInt(is[npos]) * i;
                  npos--;
            }
            nresto = ptotal % 11;
            if ((nresto == 0) || (nresto == 1))
              nresto = 11;
            nresto = 11 - nresto;  
            return (nresto == parseInt(dig2));
      case "MS":
      
            if (value.length != 9)
              return false;
            if (value.substring(0,2) != "28")
              return false;
            nro = new Array(9);
            for (let i=0; i<=8; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 9;
            soma = 0;
            for (i=0; i<=7; i++)
            {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i <= 1) { dig = 0; } else { dig = 11 - i; }
            return (dig == nro[8]);
      case "MT":
      
            if (value.length != 11)
              return false;
            die = value;
            nro = new Array(11);
            for (let i=0; i<=10; i++)
                nro[i] = parseInt(die[i]);
            b = 3;
            soma = 0;
            for (i=0; i<=9; i++)
            {
                soma += nro[i] * b;
                b--;
                if (b == 1)
                  b = 9;
            }
            i = soma % 11;
            if (i <= 1) { dig = 0; } else { dig = 11 - i; }
            return (dig == nro[10]);
      case "PA":

            if (value.length != 9)
              return false;
            if (value.substring(0, 2) != '15')
              return false;
            nro = new Array(9);
            for (let i=0; i<=8; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 9;
            soma = 0;
            for (i=0; i<=7; i++)
            {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i <= 1)
              dig = 0;
            else
              dig = 11 - i;
            return (dig == nro[8]);
      case "PB":
      
            if (value.length != 9)
              return false;
            nro = new Array(9);
            for (let i=0; i<=8; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 9;
            soma = 0;
            for (i=0; i<=7; i++)
            {
                soma += nro[i] * b;
                b--;  
            }
            i = soma % 11;
            if (i <= 1)
              dig = 0;
            else
              dig = 11 - i;
            return (dig == nro[8]);
      case "PE":

            // IE antiga com 14 digitos
            if (value.length == 14)
            {
              nro = new Array(14);
              for (let i=0; i<=13; i++)
                  nro[i] = parseInt(value.charAt(i));
              b = 5;
              soma = 0;
              for (i=0; i<=12; i++)
              {
                  soma += nro[i] * b;
                  b--;
                  if (b == 0)
                      b = 9;
              }
              dig = 11 - (soma % 11);
              if (dig > 9)
                  dig = dig - 10;
              return (dig == nro[13]);
        }

            // IE nova com 9 digitos
            if (value.length == 9)
            { 
              nro = new Array(9);
              for (let i=0; i<=8; i++)
                  nro[i] = parseInt(value.charAt(i));
              b = 8;
              soma = 0;
              for (i=0; i<=6; i++)
              {
                  soma += nro[i] * b;
                  b--;
              }
              i = soma % 11;
              if (i <= 1) { dig = 0; } else { dig = 11 - i; }
              if (dig != nro[7])
                  return false;
              b = 9;
              soma = 0;
              for (i=0; i<=7; i++)
              {
                  soma += nro[i] * b;
                  b--;
              }
              i = soma % 11;
              if (i <= 1) { dig = 0; } else { dig = 11 - i; }
              return (dig == nro[8]);
        }
        
        return false;
      case "PI":
      
            if (value.length != 9)
              return false;
            nro = new Array(9);
            for (let i=0; i<=8; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 9;
            soma = 0;
            for (i=0; i<=7; i++)
            {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i <= 1) { dig = 0; } else { dig = 11 - i; }
            return (dig == nro[8]);
      case "PR":
      
            if (value.length != 10)
              return false;
            nro = new Array(10);
            for (let i=0; i<=9; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 3;
            soma = 0;
            for (i=0; i<=7; i++)
            {
                soma += nro[i] * b;
                b--;
                if (b == 1)
                  b = 7;
            }
            i = soma % 11;
            if (i <= 1)
              dig = 0;
            else
              dig = 11 - i;
            if (dig != nro[8])
              return false;
            b = 4;
            soma = 0;
            for (i=0; i<=8; i++)
            {
                soma += nro[i] * b;
                b--;
                if (b == 1)
                  b = 7;
            }
            i = soma % 11;
            if (i <= 1)
              dig = 0;
            else
              dig = 11 - i;
            return (dig == nro[9]);
      case "RJ":
      
            if (value.length != 8)
              return false;
            nro = new Array(8);
            for (let i=0; i<=7; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 2;
            soma = 0;
            for (i=0; i<=6; i++)
            {
                soma += nro[i] * b;
                b--;
                if (b == 1)
                  b = 7;
            }
            i = soma % 11;
            if (i <= 1) { dig = 0; } else { dig = 11 - i; }
            return (dig == nro[7]);
      case "RN":

            if (value.substring(0, 2) != '20')
              return false;

            // IE com 9 digitos
        if (value.length == 9)
            {
              nro = new Array(9);
              for (let i=0; i<=8; i++)
                  nro[i] = parseInt(value.charAt(i));
              b = 9;
              soma = 0;
              for (i=0; i<=7; i++)
              {
                  soma += nro[i] * b;
                  b--;
              }
              soma *= 10;
              dig = soma % 11;
              if (dig == 10)
                  dig = 0;
              return (dig == nro[8]);
        }

            // IE com 10 digitos
            if (value.length == 10)
            {
              nro = new Array(10);
              for (let i=0; i<=9; i++)
                  nro[i] = parseInt(value.charAt(i));
              b = 10;
              soma = 0;
              for (i=0; i<=8; i++)
              {
                  soma += nro[i] * b;
                  b--;
              }
              soma *= 10;
              dig = soma % 11;
              if (dig == 10)
                  dig = 0;
              return (dig == nro[9]);
        }
            return false;
      case "RO":

            if (value.length != 14)
              return false;
            nro = new Array(14);
            b=6;
            soma=0;
            for (let i=0; i <= 12; i++)
        {
                nro[i] = parseInt(value.charAt(i));
                soma += nro[i] * b;
                b--;
                if (b == 1)
                  b = 9;
            }
            dig = 11 - ( soma % 11);
            if (dig >= 10)
              dig = dig - 10;
            return (dig == parseInt(value.charAt(13)));
      case "RR":

            if (value.length != 9)
              return false;
            if (value.substring(0,2) != "24")
              return false;
            nro = new Array(9);
            for (let i=0; i<=8; i++)
                nro[i] = parseInt(value.charAt(i));
            soma = 0;
            var no = 0;
            for (let i=0; i<=7; i++)
                soma += nro[ i ] * ++no;
            dig = soma % 9;
            return (dig == nro[8]);
      case "RS":

            if (value.length != 10)
              return false;
            nro = new Array(10);
            for (let i=0; i<=9; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 2;
            soma = 0;
            for (let i=0; i<=8; i++)
            {
                soma += nro[i] * b;
                b--;
                if (b == 1)
                    b = 9;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
              dig = 0;
            return (dig == nro[9]);
      case "SC":
    
            if (value.length != 9)
              return false;
            nro = new Array(9);
            for (let i=0; i<=8; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 9;
            soma = 0;
            for (i=0; i<=7; i++)
            {
                soma += nro[i] * b;
                b--;
            }
            i = soma % 11;
            if (i <= 1)
              dig = 0;
            else
              dig = 11 - i;
            return (dig == nro[8]);
      case "SE":

            if (value.length != 9)
              return false;
            nro = new Array(9);
            for (let i=0; i<=8; i++)
                nro[i] = parseInt(value.charAt(i));
            b = 9;
            soma = 0;
            for (i=0; i<=7; i++)
            {
                soma += nro[i] * b;
                b--;
            }
            dig = 11 - (soma % 11);
            if (dig >= 10)
              dig = 0;
            return (dig == nro[8]);
      case "SP":
            if (value.length != 12)
              return false;
            nro = new Array(12);
            for (let i=0; i<=11; i++)
              nro[i] = parseInt(value.charAt(i));
            soma = (nro[0] * 1) + (nro[1] * 3) + (nro[2] * 4) + (nro[3] * 5) + (nro[4] * 6) + (nro[5] * 7) + (nro[6] * 8) + (nro[7] * 10);
            dig = soma % 11;
            if (dig >= 10)
            dig = 0;
            if (dig != nro[8])
              return false;
            soma = (nro[0] * 3) + (nro[1] * 2) + (nro[2] * 10) + (nro[3] * 9) + (nro[4] * 8) + (nro[5] * 7) + (nro[6] * 6)  + (nro[7] * 5) + (nro[8] * 4) + (nro[9] * 3) + (nro[10] * 2);
            dig = soma % 11;
            if (dig >= 10)
            dig = 0;
            return (dig == nro[11]);
      case "TO":
          if (value.length != 9 && value.length != 11) return false;
          if (value.length == 9)
          {
            nro = new Array(9);
            b=9;
            soma=0;
            for (let i = 0; i <= 8; i++) {
              nro[i] = parseInt(value.charAt(i));
              if (i != 8) {
                soma = soma + (nro[i] * b);
                b--;
              }
            }
              var ver = soma % 11;
                if (ver < 2)
                  dig = 0;
                if (ver >= 2)
                  dig = 11 - ver;
                return (dig == nro[8]);
          }
          else {
            s = value.substring(2,4);
            if ((s != "01") && (s != "02") && (s != "03") && (s != "99")) 
              return false;
            nro = new Array(11);
            b=9;
            soma=0;
            for (let i=0; i<=9; i++)
            {
                nro[i] = parseInt(value.charAt(i));
                if( i != 2 && i != 3)
                {
                  soma += nro[i] * b;
                  b--;
                }
            }
            var resto = soma % 11;
            if ( resto < 2 ) { dig = 0; } else { dig = 11 - resto; }
            return (dig == parseInt(value.charAt(10)));
                }
      default:
            return false;
    }
  }
}