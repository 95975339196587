<template>
  <div>
    <section v-show="showRegister">
      <RegisterContacts />
    </section>
    <section v-show="showEdit">
      <EditContacts :dataContacts="$route.params.contacts" />
    </section>
  </div>
</template>

<script>
  import RegisterContacts from '@/components/contacts/RegisterContacts.vue';
  import EditContacts from '@/components/contacts/EditContacts.vue';

  export default {
    components: {
      RegisterContacts,
      EditContacts,
    },
  
    data() {
      const { activeRegister, activeEdit } = this.$route.params;

      return {
        showRegister: activeRegister,
        showEdit: activeEdit,
      };
    },
  }
</script>