<template>
  <validation-provider :name="label" :rules="rules" v-slot="validationContext">
    <b-form-group :label="label" :class="{ disabled }">
      <div class="flex items-center py-px relative">
        <b-input-group
          :append="text"
          class="items-center"
          :class="{ disabled }"
        >
          <b-form-input
            :maxlength="max"
            :step="1"
            v-model="value"
            v-mask="mask"
            @input="updateValue"
            :state="getValidationState(validationContext)"
            :placeholder="placeholder ? placeholder : label"
            :disabled="disabled"
            :type="type"
            :debounce="20"
            lazy
          />
        </b-input-group>
        <div @click="onClickIcon">
          <img
            v-if="hasIcon"
            class="w-5 mr-2 cursor-pointer"
            src="../../../../assets/img/lupa.png"
            alt="Ícone de Lupa"
          />
        </div>
      </div>
    </b-form-group>
    <div v-if="validationContext.errors[0]" class="flex">
      <small class="text-danger">{{ validationContext.errors[0] }}</small>
    </div>
  </validation-provider>
</template>

<script>
import { BFormGroup, BFormInput, BInputGroup } from "bootstrap-vue";
import { mask } from "vue-the-mask";

import {
  cpfCnpj,
  inscEstadual,
  positive,
  decimal,
} from "@/helpers/validations/validations";

import {
  min,
  max,
  email,
  max_value,
  min_value,
  numeric,
  double,
  regex,
  length,
  integer,
} from "vee-validate/dist/rules";
import { extend, ValidationProvider } from "vee-validate";
extend("email", email);
extend("min", min);
extend("max", max);
extend("regex", regex);
extend("double", double);
extend("max_value", max_value);
extend("min_value", min_value);
extend("numeric", numeric);
extend("length", length);
extend("integer", integer);
extend("cpfCnpj", cpfCnpj);
extend("decimal", decimal);
extend("positive", positive);
extend("inscEstadual", inscEstadual);
export default {
  props: [
    "label",
    "placeholder",
    "hasIcon",
    "mask",
    "hasText",
    "text",
    "rules",
    "value",
    "disabled",
    "type",
    "max",
  ],
  directives: { mask },
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
    BInputGroup,
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e);
      this.$emit("cep", e);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onClickIcon(e) {
      this.$emit("onClickIcon", e);
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/components/input-required";

.form-group {
  margin: 0 !important;
}
.input-group-text {
  background: transparent !important;
  border: none !important;
  padding: 0;
  padding-right: 10px;
  font-weight: 700 !important;
}
.form-control:disabled,
.disabled,
.disabled legend {
  background-color: #cdcdcd !important;
}
</style>
