<template>
  <div>
    <section v-show="showRegister">
      <RegisterSiproquim />
    </section>
    <section v-show="showEdit">
      <EditSiproquim :dataSiproquim="$route.params.siproquim" />
    </section>
  </div>
</template>

<script>
  import RegisterSiproquim from '@/components/siproquim/RegisterSiproquim.vue';
  import EditSiproquim from '@/components/siproquim/EditSiproquim.vue';

  export default {
    components: {
      RegisterSiproquim,
      EditSiproquim,
    },
    data() {
      const { activeRegister, activeEdit } = this.$route.params;

      return {
        showRegister: activeRegister,
        showEdit: activeEdit,
      };
    },
  }
</script>