<template>
  <button  v-if="onClick"
          class="w-48 h-12 text-base text-white font-bold rounded"
          @click.prevent="onClick"
          :type="type"
  >
    {{ buttonText }}
  </button>
  <button  v-else
          class="w-48 h-12 text-base text-white font-bold rounded"
          :type="type"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
    },
    type: {
      type:String
    }
  },
};
</script>

<style>
  .disabled{
    background-color: #cdcdcd!important;
    border: none!important;
    color:black!important
  }
</style>