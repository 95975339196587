<template>
    <div>
        <EditSiproquim />
    </div>
  </template>
  
  <script>
    import EditSiproquim from '@/components/siproquim/EditSiproquim.vue';
  
    export default {
      components: {
        EditSiproquim,
      },
      data() {
        return {
        };
      },
    }
  </script>

<style>

</style>