import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const state = {
    User: [],
}

const mutations = {
    SET_USER(state,value)
    {
        state.User = value;
    }
}

const actions = {
    async totalUser({commit}){
    let auth = localStorage.getItem('userKey');
    let authStr = JSON.parse(auth);
    let UserRes = [];

    const reqGet = await axios.get(`${process.env.VUE_APP_BASE_URL}/usuario/obter-grid/1`, {
        headers: { 'Authorization': `Bearer ${authStr}`, }
      });

      const resApi = await reqGet.data.totalDePaginas;
      const totalPages = resApi;

      for (let i = 1; i <= totalPages; i++) {
        let reqUser = await axios.get(`${process.env.VUE_APP_BASE_URL}/usuario/obter-grid/${i}`, {
          headers: { 'Authorization': `Bearer ${authStr}`, }
      });
  
       for (let i in reqUser.data.itens) {
         const resTotalUser = await reqUser.data.itens[i];
  
         UserRes.push(resTotalUser);
         commit('SET_USER', UserRes);
       }
      }

    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
