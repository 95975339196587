<template>
  <main>
    <h1>{{ $t("sectionRegisters.registeUser") }}</h1>
    <form>
      <h4>{{ $t("sectionRegisters.fillInForm") }}</h4>
      <input v-model="description" type="text" placeholder="Digite uma descrição">
      <button class="btn-save" @click="save">{{ $t("sectionRegisters.save") }}</button>
    </form>
    <router-link class="styleLink" to="/groupUsers/registerClients">
      <button class="btn-return">{{ $t("sectionRegisters.back") }}</button>
    </router-link>
  </main>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Register',
    data() {
      return {
        description: '',
      }
    },
    methods: {
      save(e) {
        e.preventDefault();

        if (this.description != '' && this.description != ' ') {
          let auth = localStorage.getItem('userKey');
          let authStr = JSON.parse(auth);

          const bodyRequest = {
            descricao: this.description,
            contaId: '3c5d1b6c-c0c3-4774-a307-992c25c2bda2',
            excluido: false,
          }

          axios.post(`${process.env.VUE_APP_BASE_URL}/grupoUsuario/novo`, bodyRequest, {
            headers: { 'Authorization': `Bearer ${authStr}`,}
          })
          .then(() => {
            this.description = '';
            this.$store.dispatch('groupUsers/totalGroupUsers');
          })
          .catch(() => console.log('Houve um erro.'));
        }
      }
    }
  }
</script>

<style>
  .btn-save {
    border: 1.5px grey solid;
    border-radius: 5px;
    padding: 5px;
    background-color: chartreuse;
  }

  .btn-return {
    border: 1.5px grey solid;
    border-radius: 5px;
    padding: 5px;
  }

  .styleLink {
    width: 150px;
    height: 0px;
  }  
</style>