<template>
    <div>
        <RegisterSiproquim />
    </div>
  </template>
  
  <script>
    import RegisterSiproquim from '@/components/siproquim/RegisterSiproquim.vue';
  
    export default {
      components: {
        RegisterSiproquim,

      },
      data() {
  
        return {
        };
      },
    }
  </script>

<style>

</style>