import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "../static/css/tailwind.css";
import "../static/css/style.css";

// 3rd party plugins
import "@axios";
import i18n from "@/libs/i18n";
import VueMeta from "vue-meta";

import MQ from "vue-match-media/src";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
// Import global styles
require("@/assets/scss/style.scss");

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMeta);
Vue.use(MQ);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  mq: {
    mobile: "(max-width: 500px)",
    tablet: "(min-width: 1024px)",
    notebook: "(max-width: 1366px)",
    desktop: "(min-width: 1400px)",
  },
  render: (h) => h(App),
}).$mount("#app");
