import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import groupUsers from "./modules/groupUsers";
import dcb from "./modules/dcb";
import methods from "@/store/modules/methods";
import classe from "@/store/modules/classe";
import stateTypesContacts from "./modules/stateTypesContacts";
import groupPermission from "./modules/groupPermission";
import groupProducts from "./modules/groupProducts";
import siproquimState from "./modules/siproquimState";
import pbmState from "./modules/pbmState";
import stateFormaFarma from "./modules/stateFormaFarma";
import stateUser from "./modules/stateUser";
import fornecedores from "./modules/fornecedores";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    groupUsers,
    methods,
    dcb,
    groupPermission,
    groupProducts,
    classe,
    stateTypesContacts,
    siproquimState,
    pbmState,
    stateFormaFarma,
    stateUser,
    fornecedores,
  },
  strict: debug,
  plugins: [],
});
