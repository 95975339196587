<template>
  <div class="content-registerContact">
    <h1>{{ $t("sectionFormaFarma.RegisterFarma") }}</h1>
    <form class="form-registerContact">
      <h4>{{ $t("sectionRegisters.fillInForm") }}</h4>
      <label>
        <h6>{{ $t("sectionFormaFarma.description") }}</h6>
        <input v-model="description" type="text" placeholder="Digite uma descrição"><br>
      </label>
      <button @click="save">{{ $t("sectionRegisters.save") }}</button>
    </form>
    <router-link style="margin-right: 0px" to="/typeContact/areaTypesContacts">
        <button class="btn-returnContact" style="margin-right: 30px">{{ $t("sectionRegisters.back") }}</button>
    </router-link>
  </div>
</template>

<script>
  import axios from 'axios';
  import {v4 as uuidv4} from 'uuid';

  export default {
    name: 'RegisterContacts',
    data() {
      return {
        description: '',
        TipoFormaMetodo:''
      }
    },
    methods: {
      save(e) {
        e.preventDefault();

        if (this.description != '' && this.description != ' ') {
          let auth = localStorage.getItem('userKey');
          let authStr = JSON.parse(auth);

          const bodyRequest = {
            descricao: this.description,
            integracaoId: uuidv4(),
            excluido: false,
          }

          axios.post(`${process.env.VUE_APP_BASE_URL}/formaFarmaceutica/novo`, bodyRequest, {
            headers: { 'Authorization': `Bearer ${authStr}`,}
          })
          .then(() => {
            this.description = '';
            this.$store.dispatch('stateFormaFarma/totalFormaFarma');
          })
          .catch(() => console.log('Houve um erro.'));
        }
      }
    }
  }
</script>

<style scoped>
  .content-registerContact {
    display: flex;
    flex-direction: column;
  }

  .form-registerContact {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form-registerContact label {
    margin-top: 10px;
  }

  .form-registerContact label h6 {
    margin-right: 150px;
    font-size: 1.2em;
  }

  .form-registerContact label input {
    padding: 5px;
    margin-right: 35px;
  }

  .form-registerContact button {
    padding: 5px;
    width: 100px;
    margin-right: 30px;
    text-align: center;
    align-self: center;
    color: black;
    border-radius: 5px;
    background-color: green;
  }

  .btn-returnContact {
    padding: 5px;
    width: 100px;
    margin-top: 10px;
    margin-right: 30px;
    text-align: center;
    align-self: center;
    color: black;
    border: 1px gray solid;
    border-radius: 5px;
  }
</style>