import axios from 'axios'
import Vuex from 'vuex';
import Vue from 'vue'

Vue.use(Vuex);

const state = {
    classe: []
}

const mutations = {
    SET_CLASSE(state, value){
        state.classe = value
    } 
}

const actions = {
    async classeSearch({ commit }) {

     let classeAllItens = []   
        
     let pageclasse =  await axios.get(`${process.env.VUE_APP_BASE_URL}/classe/obter-grid/1` )
     
     let allPages = await pageclasse.data.totalDePaginas
     let  PagesOK = allPages 
     
     for ( let i = 1; i <= PagesOK; i++ ){
        let pageclasseReq =  await axios.get(`${process.env.VUE_APP_BASE_URL}/classe/obter-grid/${i}`, )    

        for ( let i in pageclasseReq.data.itens ){
            const classeTotal = await pageclasseReq.data.itens[i];

            classeAllItens.push(classeTotal);

            commit('SET_CLASSE', classeAllItens);
        }
      }
    }
}

export default{
    namespaced: true, state, mutations, actions
}
