<template>
  <div>
    <div v-show="showRegister">
      <Register />
    </div>
    <div v-show="showEdit"> 
      <Edit :dataUsers="$route.params.user" />
    </div>
  </div>
</template>

<script>
  import Register from '@/components/cadastros/Register.vue';
  import Edit from '@/components/cadastros/Edit.vue';

  export default {
    components: {
      Register,
      Edit,
    },
    data() {
      const { activeRegister, activeEdit } = this.$route.params;

      return {
        showRegister: activeRegister,
        showEdit: activeEdit,
      };
    },
  }
</script>