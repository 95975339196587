<template>
  <div>
    <header class="styleNewPbm">
      <h1>PBM</h1>
    </header>
    <form class="formNewPbm">
      <div class="codPbm" style="width: 694px;">
        <h6 class="pl-2 pt-1 mb-0">
          Nome <span style="color: #CF0209;">*</span>
        </h6>
        <input class="px-2 py-1 m-0" type="text" />
      </div>
      <div class="describePbm" style="width: 694px;">
        <h6 class="pl-2 pt-1 mb-0">Observação</h6>
        <input class="px-2 py-1 m-0" type="text" />
      </div>
    </form>
      <div class="content-btnNewPbm" style="display: flex; justify-content: flex-start; margin-top: 117px;">
        <b-button 
          id="show-btn"
          @click="$bvModal.show('bv-modal-example')"
          class="button-sucess btn-sucessNewPbm"
          variant="success"
        >
          Confirmar
        </b-button>
        <b-modal class="modalNewPbm" id="bv-modal-example" hide-footer>
          <div class=" modalNewPbm d-block text-center content-modelGeneral">
            <h3 class="model-content1">Cadastro foi realizado com</h3>
            <h3 class="model-content2">Sucesso!</h3>
          </div>
        </b-modal>
        <router-link 
          style="width: 0px; height: 0px;" 
          to="/pbm/areaPbm"
        >
          <button class="btn-cancelNewPbm">
            Cancelar
          </button>
        </router-link>
      </div>  
    <!-- <h1>{{ $t("sectionSiproquim.register") }}</h1>
    <form class="form-registerPbm">
      <h4>{{ $t("sectionRegisters.fillInForm") }}</h4>
      <label>
        <h6 style="margin-right: 130px">Integração ID</h6>
        <input
          v-model="integracaoId"
          type="text"
          placeholder="Digite uma integração"
        />
      </label>
      <label>
        <h6 style="margin-right: 180px">Nome</h6>
        <input v-model="nome" type="text" placeholder="Digite um nome" />
      </label>
      <label>
        <h6 style="margin-right: 160px">Obserção</h6>
        <input
          v-model="observacao"
          type="text"
          placeholder="Digite uma observação"
        />
      </label>
      <button @click="save">{{ $t("sectionRegisters.save") }}</button>
    </form>
    <router-link style="margin-right: 0px" to="/pbm/areaPbm">
      <button class="btn-returnPbm" style="margin-right: 30px">
        {{ $t("sectionRegisters.back") }}
      </button>
    </router-link> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RegisterPbm",
  data() {
    return {
      integracaoId: "",
      nome: "",
      observacao: "",
    };
  },
  methods: {
    save(e) {
      e.preventDefault();

      if (this.nome != "" && this.nome != " ") {
        let auth = localStorage.getItem("userKey");
        let authStr = JSON.parse(auth);

        const bodyRequest = {
          integracaoId: this.integracaoId,
          nome: this.nome,
          observacao: this.observacao,
        };

        axios
          .post(`${process.env.VUE_APP_BASE_URL}/pbm/novo`, bodyRequest, {
            headers: { Authorization: `Bearer ${authStr}` },
          })
          .then(() => {
            this.integracaoId = "";
            this.nome = "";
            this.observacao = "";
            this.$store.dispatch("pbmState/totalPbm");
          })
          .catch(() => console.log("Houve um erro."));
      }
    },
  },
};
</script>

<style scoped>
.content-registerPbm {
  display: flex;
  flex-direction: column;
}

.form-registerPbm {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-registerPbm label {
  margin-top: 10px;
}

.form-registerPbm label h6 {
  margin-right: 150px;
  font-size: 1.2em;
}

.form-registerPbm label input {
  padding: 5px;
  margin-right: 35px;
}

.form-registerPbm button {
  padding: 5px;
  width: 100px;
  margin-right: 30px;
  text-align: center;
  align-self: center;
  color: black;
  border-radius: 5px;
  background-color: green;
}

.btn-returnPbm {
  padding: 5px;
  width: 100px;
  margin-top: 10px;
  margin-right: 30px;
  text-align: center;
  align-self: center;
  color: black;
  border: 1px gray solid;
  border-radius: 5px;
}
</style>
