<template>
  <div
    class="w-full flex flex-col sm:flex-row justify-between items-start sm:items-center header__register"
    :class="{
      'pb-1': showButtonView && subTitleText,
      'pb-3':
        (showButtonView && !subTitleText) || (!showButtonView && subTitleText),
      'pb-4': !showButtonView && !subTitleText,
    }"
  >
    <div v-if="subTitleText" class="flex flex-col items-start">
      <h2 class="m-0 text-2xl sm:text-3xl">
        <slot />
      </h2>
      <p class="w-full text-sm sm:text-left text-gray-600 font-normal mt-1">
        {{ subTitleText }}
      </p>
    </div>
    <h2 v-else class="m-0 text-2xl sm:text-3xl">
      <slot />
    </h2>
    <div
      v-if="buttonText"
      class="w-full sm:w-auto"
      :class="{
        flex: subTitleText,
        'justify-end': subTitleText,
      }"
    >
      <b-button
        v-if="showButtonView"
        @click="click || RedirectUrl"
        class="btnNovo  text-sm sm:text-lg"
        variant="danger"
      >
        {{ buttonText }}
      </b-button>
    </div>
    <div v-else>
      <b-button
        v-if="showButtonView"
        @click="click != null ? click() : RedirectUrl()"
        class="btnIncluir  text-sm sm:text-lg"
        variant="danger"
      >
        {{ $t("commons.toInclude") }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderRegister",
  props: {
    showButtonView: {
      type: Boolean,
      default: true,
    },
    showUrl: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    subTitleText: {
      type: String,
    },
    click: {
      type: Function,
      required:false,
    }
  },
  methods: {
    RedirectUrl() {
      return this.$router.push(this.showUrl);
    },
    changeShowForm() {
      this.showFormComponent = true;
      this.$emit("update:showFormCreate", this.showFormComponent);
    },
  },
};
</script>

<style lang="scss">
.header__register {
  border-bottom: 1px solid #5b68735d;
  h2 {
    font-family: "Segoe_UI_Black_2";
    color: #cf0209;
  }
}
.more__icon,
.icon__actions {
  fill: #5b6873;
}
.btnIncluir,
.btnNovo {
  background: #cf0209 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  width: 6rem !important;
  padding-bottom: 0.2rem !important;
  padding-top: 0.2rem !important;
  border-color: #cf0209;
}
.btnNovo {
  font-weight: normal;
}
//Resolution 992px
@media (max-width: 992px) {
  .asdf {
    font-size: 0.85em;
  }
}
</style>
