import useJwt from "@/auth/jwt/useJwt";

export const isUserLoggedIn = () => {
  return (
    localStorage.getItem(useJwt.jwtConfig.storageUserDataName) &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  );
};

export const getUserData = () =>
  JSON.parse(localStorage.getItem(useJwt.jwtConfig.storageUserDataName));
