import axios from 'axios'
import Vuex from 'vuex';
import Vue from 'vue'

Vue.use(Vuex);

const state = {
    dcb: [],
    Cod: null,
    Desc: null
}

const mutations = {
    SET_DCB(state, value){
        state.dcb = value
    } 
}

const actions = {
    async dcbSearch({ commit }) {

     let dcbAllItens = []   
        
     let pagedcb =  await axios.get(`${process.env.VUE_APP_BASE_URL}/dcb/obter-grid/1` )
     
     let allPages = await pagedcb.data.totalDePaginas
     let  PagesOK = allPages 
     
     for ( let i = 1; i <= PagesOK; i++ ){
        let pagedcbReq =  await axios.get(`${process.env.VUE_APP_BASE_URL}/dcb/obter-grid/${i}`, )    

        for ( let i in pagedcbReq.data.itens ){
            const dcbTotal = await pagedcbReq.data.itens[i];

            dcbAllItens.push(dcbTotal);

            commit('SET_DCB', dcbAllItens);
        }
      }
    }
}

export default{
    namespaced: true, state, mutations, actions
}
