<template>

  <section class="w-full">
    <header-register :showButtonView="false">Código siproquim
    </header-register>
    <div class="grid grid-rows-2 pt-2 w-full md:w-1/2">
      <div class="pt-1 md:col-span-1">
        <input-default v-model="codeSiproquim" :type="`text`" :label="`Código siproquim`" disabled />
      </div>
      <div class="pt-2 md:col-span-2">
        <input-default v-model="description" :type="`text`" :label="`Descrição`" disabled />
      </div>
    </div>
    <footer class="w-full h-96 flex items-end justify-start gap-5 my-10">
      <Button class="bg-gray-900 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-gray-700 transition-all duration-300"
        buttonText="Voltar" :onClick="redirectUrl" />
    </footer>
  </section>

</template>

<script>
import HeaderRegister from '@/@core/components/register/HeaderRegister.vue';
import InputDefault from '@core/components/commons/inputs/InputDefault';

import Button from '@/@core/components/commons/button/Button.vue';

export default {
  name: "RegisterSiproquim",
  components: {
    HeaderRegister,
    InputDefault,
    Button,
  },
  data() {
    return {
      description: "",
      codeSiproquim: "",
      ncmId: "",
      modalIsOpen: false,
      modalError: false,
      modalErrorMessage: null,
      modalErroSalvar: false,
    };
  },
  created(){
    this.codeSiproquim = this.$store.state.codSip
    this.description = this.$store.state.Descri
  },
  methods: {
    redirectUrl() {
      return this.$router.push("/siproquim/areaSiproquim");
    },
    async openModal() {
      if (this.codeSiproquim == "" || this.description == "") this.modalError = true
      else this.modalIsOpen = true;
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalError = false;
        this.modalErroSalvar = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style>

</style>
