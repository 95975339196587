<template>
  <validation-provider :name="label" :rules="rules" v-slot="validationContext">
    <b-form-group
      aria-describedby="required"
      :label="label"
      :class="{ disabled }"
    >
      <b-form-input
        :maxlength="max"
        v-model="value"
        v-mask="mask"
        :type="type"
        @input="updateValue"
        :state="getValidationState(validationContext)"
        :placeholder="placeholder ? placeholder : label"
        :disabled="disabled"
        :debounce="2"
        lazy
      />
    </b-form-group>
    <div v-if="validationContext.errors[0]" class="flex">
      <small class="text-danger">{{ validationContext.errors[0] }}</small>
    </div>
  </validation-provider>
</template>

<script>
import {
  cpfCnpj,
  inscEstadual,
  maiorQ,
} from "@/helpers/validations/validations";

import {
  required,
  min,
  max,
  email,
  max_value,
  min_value,
  numeric,
  regex,
  double,
  length,
} from "vee-validate/dist/rules";
import { extend, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("maiorQ", maiorQ);
extend("regex", regex);
extend("double", double);
extend("max_value", max_value);
extend("min_value", min_value);
extend("numeric", numeric);
extend("length", length);
extend("cpfCnpj", cpfCnpj);
extend("inscEstadual", inscEstadual);

export default {
  props: [
    "label",
    "placeholder",
    "rules",
    "value",
    "mask",
    "disabled",
    "type",
    "max",
  ],
  directives: { mask },
  components: {
    ValidationProvider,
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/components/input-required";
.form-control:disabled,
.disabled,
.disabled legend {
  background-color: #cdcdcd !important;
}
</style>
