<template>
  <ValidationObserver>
    <section class="w-full">
      <header-register :showButtonView="false">Código siproquim
      </header-register>
      <div class="grid grid-rows-2 pt-2 w-full md:w-1/2">
        <div class="pt-1 md:col-span-1">
          <input-required v-model="codeSiproquim" :type="`text`" :label="`Código siproquim`"
            :rules="{ required:true,max:50}" max="50" />
        </div>
        <div class="pt-2 md:col-span-2">
          <input-required v-model="description" :type="`text`" :label="`Descrição`" :rules="{ required:true,max:50}"
            max="50" />
        </div>
        <div class="flex">
          <span class="text-red-600">Os campos com * são de preenchimento obrigatório</span>
        </div>
      </div>
      <footer class="w-full h-96 flex items-end justify-start gap-5 my-10">
        <Button
          class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
          buttonText="Confirmar" type="submit" :onClick="openModal" />
        <Button class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
          buttonText="Cancelar" :onClick="redirectUrl" />
      </footer>
      <Modal v-if="modalIsOpen" :modalIsOpen.sync="modalIsOpen" :closeModal="closeModal"
        :modalText="$t('commons.modalTextCreate')" />

      <Modal v-if="modalError" :modalIsOpen.sync="modalError" :closeModal="closeModal"
        :modalText="$t('commons.modalErrorCreate')" />
      <Modal v-if="modalErroSalvar" :modalIsOpen.sync="modalErroSalvar" :closeModal="closeModal"
        :modalText="$t('commons.modalErroSalvar')" />
    </section>
  </ValidationObserver>
</template>

<script>
import HeaderRegister from '@/@core/components/register/HeaderRegister.vue';
import InputRequired from '@core/components/commons/inputs/InputRequired';
import Modal from '@/@core/components/commons/modal/Modal.vue';
import Button from '@/@core/components/commons/button/Button.vue';
import { ValidationObserver } from "vee-validate";

export default {
  name: "RegisterSiproquim",
  components: {
    HeaderRegister,
    InputRequired,
    Modal,
    Button,
    ValidationObserver
  },
  data() {
    return {
      description: "",
      codeSiproquim: "",
      ncmId: "",
      modalIsOpen: false,
      modalError: false,
      modalErrorMessage: null,
      modalErroSalvar: false,
    };
  },
  methods: {
    redirectUrl() {
      return this.$router.push("/siproquim/areaSiproquim");
    },
    async openModal() {
      if(this.codeSiproquim=="" || this.description=="") this.modalError = true
      else this.modalIsOpen = true;
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalError = false;
        this.modalErroSalvar = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style>

</style>
