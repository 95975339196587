<template>
  <div>
    <form>
      <h4>{{ $t("sectionRegisters.editDatas") }}</h4>
      <input v-model="description" type="text">
      <button class="btn-save" @click="save">{{ $t("sectionRegisters.save") }}</button>
    </form>
     <router-link to="/groupUsers/registerClients">
      <button class="btn-return">{{ $t("sectionRegisters.back") }}</button>
    </router-link>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'Edit',
    props: {
      dataUsers: Object,
    },
    data() {
      return {
        description: this.dataUsers.descricao,
        editUser: '',
      };
    },
  
    async mounted() {
      this.$store.dispatch('groupUsers/totalGroupUsers');
      
      const { descricao } = this.dataUsers;
      const { groupUsers } = this.$store.state.groupUsers;
      const findUser = groupUsers.find(user => user.descricao == descricao);

      this.editUser = findUser;
    },

    methods: {
      save(e) {
        e.preventDefault();

        if (this.description != this.dataUsers.descricao) {
          let auth = localStorage.getItem('userKey');
          let authStr = JSON.parse(auth);

          const { id, contaId } = this.editUser;
 
          const bodyRequest = {
            descricao: this.description,
            id: id,
            contaId: contaId,
            excluido: false,
          }

          axios.post(`${process.env.VUE_APP_BASE_URL}/grupoUsuario/editar`, bodyRequest, {
            headers: { 'Authorization': `Bearer ${authStr}`, }
          })
          .then(() => {
            this.description = '';
            this.$store.dispatch('groupUsers/totalGroupUsers');
          })
          .catch(() => console.log('Houve um erro.'));
        }
      }
    }
  }
</script>

<style>
  .btn-return {
    border: 1.5px grey solid;
    border-radius: 5px;
    padding: 5px;
  }

  .btn-save {
    border: 1.5px grey solid;
    border-radius: 5px;
    padding: 5px;
    background-color: chartreuse;
  }
</style>